import { render, staticRenderFns } from "./TasqDetailMobile.vue?vue&type=template&id=45ca6e60&scoped=true&"
import script from "./TasqDetailMobile.vue?vue&type=script&lang=ts&"
export * from "./TasqDetailMobile.vue?vue&type=script&lang=ts&"
import style0 from "./TasqDetailMobile.vue?vue&type=style&index=0&id=45ca6e60&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ca6e60",
  null
  
)

export default component.exports